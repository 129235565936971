import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import PropTypes from 'prop-types';

import CloseIcon from '../assets/icons/close.svg';

export default function Modal({ title, children, onClose }) {
  return (
    <div className="modalWrapper" style={{ left: 0 }}>
      <div className="wideModal scrollable">
        <img
          className="modal-close-button"
          src={CloseIcon}
          alt="Close button for modal"
          onClick={onClose}
        />
        {title && (
          <div className="text-center pb-1">
            <h2>{title}</h2>
          </div>
        )}
        {children}
        <div className="modalFooter">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  backgroundUrl: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
  callToActionText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onCallToAction: PropTypes.func.isRequired,
};
