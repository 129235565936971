import React from 'react';
import { useInstantSearch } from 'react-instantsearch';

const ViewedProgress = ({ prefix = '', suffix = '' }) => {
  const { results } = useInstantSearch();

  if (!results) {
    return null;
  }

  const hitsPerPage = results.hitsPerPage;
  const currentPage = results.page + 1;
  const totalHits = results.nbHits;

  const itemsViewed = Math.min(currentPage * hitsPerPage, totalHits);

  return (
    <p style={{ marginBottom: 0 }}>
      {prefix} <strong>{itemsViewed}</strong> out of <strong>{totalHits}</strong> {suffix}
    </p>
  );
};

export default ViewedProgress;
