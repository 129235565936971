import { formatQualification } from '@src/utils/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { useCurrentRefinements } from 'react-instantsearch';

export const useAreaDescriptions = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsAreaDescription {
        nodes {
          description
          qualification {
            title
          }
          area {
            title
          }
        }
      }
    }
  `);

  // searched areas
  const areaRefinements = useCurrentRefinements({ includedAttributes: ['area'] });
  const areas = areaRefinements.items.at(0)?.refinements.map((item) => item.label) || [];

  // search qualification
  const qualRefinements = useCurrentRefinements({ includedAttributes: ['qualification'] });
  const qualification = qualRefinements.items.at(0)?.refinements.map((item) => item.label) || [];

  // dato areas with descriptions
  const areasWithDescriptions = useMemo(() => {
    return data.allDatoCmsAreaDescription.nodes.filter((node) => {
      return (
        areas.includes(node.area.title) &&
        qualification.includes(node.qualification.title.replace(' ', '-'))
      );
    });
  }, [areaRefinements, qualRefinements]);

  return {
    areas: areasWithDescriptions,
    count: areasWithDescriptions.length,
  };
};
