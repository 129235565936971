import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';
import { styles } from './styles';

type ManualSelectProps = UseRefinementListProps & {
  options?: Array<{
    label: string;
    value: string;
  }>;
  onChange?: (value?: string) => void;
  formatLabel?: (label: string) => string;
};

const ManualSelect = ({ options, onChange, formatLabel, ...props }: ManualSelectProps) => {
  const { items, refine } = useRefinementList({
    ...props,
    limit: 100,
  });

  // Prepare the options for React Select
  const selectOptions = useMemo(() => {
    return options.map(({ label, value }) => {
      const optionLabel = formatLabel ? formatLabel(label) : label;
      return {
        label: optionLabel,
        value,
        count: undefined,
        isRefined: false,
      };
    });
  }, [options, formatLabel]);

  const selectedValue = items.find((item) => item.isRefined);

  const handleChange = (selectedOption: { label: string; value: string }) => {
    onChange?.();
    refine(selectedOption.value);
  };

  return (
    <Select
      isMulti={false}
      options={selectOptions}
      value={{
        label: formatLabel?.(selectedValue?.label),
        value: selectedValue?.value,
      }}
      onChange={handleChange}
      styles={styles}
    />
  );
};

export default memo(ManualSelect);
