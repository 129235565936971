import {
  Configure,
  ConfigureProps,
  InstantSearch,
  useCurrentRefinements,
  useInstantSearch,
} from 'react-instantsearch';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { graphql, Link } from 'gatsby';
import React, { PropsWithChildren, useRef, useState } from 'react';
import { QB_ROOT_PATH } from '@src/constants/config';
import { Layout, SEO } from 'components';
import { Hits, Pagination, SearchBox } from 'react-instantsearch';

import type { Hit } from 'instantsearch.js';
import { removeHyphens } from '@src/utils/utils';
import { useIsQbSubscribed } from '@src/hooks/user';
import SearchFilters from '@src/components/UI/QuestionBank/SearchFilters';
import ViewedProgress from '@src/components/Search/ViewedProgress';
import QuestionBankGuard from '@src/guards';
import Lightbox from 'yet-another-react-lightbox';
import { WorksheetCheckbox, WorkSheetMaker } from 'components/WorksheetMaker';
import MagnifyIcon from '../../assets/icons/magnify.svg';

// Styles
import 'yet-another-react-lightbox/styles.css';
import 'instantsearch.css/themes/satellite.css';
import * as styles from './question-bank.module.css';

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    apiKey: process.env.GATSBY_TYPESENSE_SEARCH_ONLY_API_KEY, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: process.env.GATSBY_TYPESENSE_HOST,
        port: 443,
        protocol: 'https',
      },
    ],
  },
  collectionSpecificSearchParameters: {
    questions: {
      query_by:
        'slug,resource_type,qualification,series,area,paper,difficulty,subtopic,division,keyword',
      sort_by: 'series:asc,paper:asc,question_number:asc',
    },
  },
});

type HitProps = {
  hit: Hit;
};

function HitMe({ hit }: HitProps) {
  const slug = `/question-bank/${hit.slug}`;
  const imgSrc = `${QB_ROOT_PATH}${hit.image_question}`;

  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  const title = `${removeHyphens(hit.series)} - ${removeHyphens(hit.paper)} -  Q${
    hit.question_number
  }`;

  return (
    <div>
      <div style={{ padding: '10px' }}>
        <WorksheetCheckbox
          hit={{
            title,
            slug: hit.slug,
          }}
        />
      </div>

      <div className={styles.hitHeader}>
        <Link to={slug}>
          {removeHyphens(hit.series)} - {removeHyphens(hit.paper)}
          <div>
            Question {hit.question_number} - {hit.difficulty}
          </div>
        </Link>
      </div>
      <div className={styles.hitImage}>
        <button className={styles.magnify} onClick={() => setOpen(true)}>
          <span>Enlarge Image</span>
          <img src={MagnifyIcon} alt="Enlarge Paper" />
        </button>

        <Link to={slug}>
          <img className="my-3" src={imgSrc} alt="Paper" />
        </Link>

        <Lightbox
          open={open}
          className={styles.lightbox}
          close={close}
          carousel={{
            finite: true,
          }}
          slides={[
            {
              src: imgSrc,
              width: 1000,
            },
          ]}
        />
      </div>
      <div className={styles.hitFooter}>
        <Link to={slug}>
          <p>{hit.area.join(', ')}</p>
        </Link>
      </div>
    </div>
  );
}

const searchClient = typesenseInstantsearchAdapter.searchClient;

const future = { preserveSharedStateOnUnmount: true };

const PromotionVideo = ({ src }: { src: string }) => {
  return (
    <video className={styles.promotionVideo} controls>
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

const Subsribe = ({ children }: PropsWithChildren) => {
  const { results } = useInstantSearch();
  const filters = useCurrentRefinements();

  const hits = filters.items.length ? results.nbHits : 0;

  return (
    <div className={styles.subscription}>
      <div className={styles.subscriptionText}>
        <p>You need to be subscribed to view the results of this question bank.</p>
        <p>
          The search is available above so you can see all the search filters offered and whether we
          have documents for your course.
        </p>
        <p>
          Based on the current filters we would show <b>{hits}</b> questions along with their mark
          schemes and written mark schemes.
        </p>
        <Link className="button w-button m-0" to="/subscriptions">
          Subscribe
        </Link>
      </div>
      {children}
    </div>
  );
};

const QuestionBank = ({ data: { datoCmsPage } }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const goToTop = () => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const configureProps = {
    hitsPerPage: 12,
  } as ConfigureProps;

  const isSubscribed = useIsQbSubscribed();

  return (
    <Layout>
      <WorkSheetMaker />

      <InstantSearch searchClient={searchClient} indexName="questions" future={future} routing>
        <Configure {...configureProps} />
        <SEO title="Question Bank" noIndex schema={''} seoKeywords={''} />
        <article className="content-section" id="search">
          <div className="container">
            <div>
              <div className="container" ref={containerRef}>
                <div className={styles.searchPanel}>
                  <SearchFilters />
                  <div className={styles.searchPanelResults}>
                    <SearchBox placeholder="" className={styles.searchbox} />

                    {isSubscribed ? (
                      <Hits
                        hitComponent={HitMe}
                        classNames={{
                          list: styles.hitList,
                          item: styles.hitItem,
                        }}
                      />
                    ) : (
                      <Subsribe>
                        {datoCmsPage?.video && <PromotionVideo src={datoCmsPage.video.url} />}
                      </Subsribe>
                    )}

                    {isSubscribed && (
                      <div className={styles.pagination}>
                        <QuestionBankGuard />
                        <ViewedProgress prefix="You've viewed" suffix="Questions" />
                        <Pagination onClick={goToTop} />
                        <button className="ais-Pagination-link" onClick={goToTop}>
                          Return to top
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </InstantSearch>
    </Layout>
  );
};

export default QuestionBank;

const query = graphql`
  query {
    datoCmsPage(slug: { eq: "question-bank" }) {
      video {
        url
      }
    }
  }
`;
export { query };
