import React, { memo, useMemo } from 'react';
import Select from 'react-select';
import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';
import { styles } from './styles';

type CustomSelectProps = UseRefinementListProps & {
  isMulti?: boolean;
  overrideOptions?: Array<{
    label: string;
    value: string;
  }>;
  debug?: boolean;
  formatLabel?: (label: string) => string;
};

const CustomSelect = ({
  debug,
  overrideOptions,
  isMulti = true,
  formatLabel,
  ...props
}: CustomSelectProps) => {
  const { items, refine } = useRefinementList({
    ...props,
    limit: 1000,
  });

  // Prepare the options for React Select
  const options = useMemo(() => {
    if (overrideOptions) {
      return overrideOptions;
    }

    return items.map(({ label, value, count, ...rest }) => {
      const optionLabel = formatLabel ? formatLabel(label) : label;
      return {
        label: `${optionLabel} (${count})`,
        value,
        count,
        ...rest,
      };
    });
  }, [items, overrideOptions, formatLabel]);

  // Determine the currently selected values
  const selectedValues = items
    .filter((item) => item.isRefined)
    .map((item) => ({
      label: formatLabel ? formatLabel(item.label) : item.label,
      value: item.value,
    }));

  const handleChange = (selectedOptions) => {
    // Extract the values from selected options
    const selected = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : [selectedOptions].map((option) => option.value);

    if (debug) {
      console.log('selected: ', selected);
    }

    // Iterate through all items to refine based on selection
    items.forEach((item) => {
      if (selected.includes(item.value) && !item.isRefined) {
        refine(item.value);
      } else if (!selected.includes(item.value) && item.isRefined) {
        refine(item.value);
      }
    });
  };

  return (
    <Select
      isMulti={isMulti}
      isDisabled={options.length === 0}
      options={options}
      value={selectedValues}
      onChange={handleChange}
      styles={styles}
    />
  );
};

export default memo(CustomSelect);
