import React, { useState } from 'react';
import Modal from '@src/components/Modal';
import { useAreaDescriptions } from '@src/hooks/useAreaDescriptions';

const AreaInfoModal = () => {
  const [open, setOpen] = useState(false);
  const { areas: areaDescriptions, count } = useAreaDescriptions();
  return (
    <div>
      {count > 0 && (
        <button className="button" onClick={() => setOpen(true)}>
          Information <span>{count}</span>
        </button>
      )}
      {open && (
        <Modal title="Information" onClose={() => setOpen(false)}>
          {areaDescriptions?.map((d) => {
            return (
              <div style={{ margin: '20px 0' }} key={d.area.title}>
                <h3>{d.area.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: d.description }} />
              </div>
            );
          })}
        </Modal>
      )}
    </div>
  );
};

export default AreaInfoModal;
