// extracted by mini-css-extract-plugin
export var demoResults = "question-bank-module--demo-results--iAUqS";
export var hitFooter = "question-bank-module--hit-footer--Nssyx";
export var hitHeader = "question-bank-module--hit-header--9-qNC";
export var hitImage = "question-bank-module--hit-image--ImVHA";
export var hitItem = "question-bank-module--hit-item--rlgpR";
export var hitList = "question-bank-module--hit-list--l3b6Y";
export var lightbox = "question-bank-module--lightbox--+MmWM";
export var magnify = "question-bank-module--magnify--YdKV4";
export var pagination = "question-bank-module--pagination--DY5jU";
export var promotionVideo = "question-bank-module--promotionVideo--dcceR";
export var searchPanel = "question-bank-module--search-panel--1gp9m";
export var searchbox = "question-bank-module--searchbox--RMAh4";
export var subscription = "question-bank-module--subscription--JX0o9";
export var subscriptionText = "question-bank-module--subscription-text--HZ9mN";