import { StylesConfig } from 'react-select';

export const styles: StylesConfig = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: state.isFocused ? 'hsla(var(--mmc-blue))' : 'hsla(var(--mmc-pink))',
    cursor: state.selectProps.isDisabled ? 'not-allowed' : 'pointer',
    pointerEvents: 'auto',
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: 'hsla(var(--mmc-blue) / 50%)',
  }),
  multiValueRemove: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: 'hsla(var(--mmc-blue) / 50%)',
    ':hover': {
      backgroundColor: 'hsla(var(--mmc-pink) / 50%)',
    },
  }),
  option(base, props) {
    return {
      ...base,
      backgroundColor:
        props.isSelected || props.isFocused ? 'hsla(var(--mmc-blue) / 50%)' : 'transparent',
      ':hover': {
        backgroundColor: 'hsla(var(--mmc-blue) / 50%)',
      },
    };
  },
};
